<script>

export default {
  parseMinMaxItem (name, mi, ma) {
    const ret = {};
    const min = parseFloat(mi);
    const max = parseFloat(ma);
    if (!isNaN(min) && !isNaN(max)) {
      ret[name] = { $between: [ min, max ] };
    } else if (!isNaN(min) && isNaN(max)) {
      ret[name] = { $gte: min };
    } else if (isNaN(min) && !isNaN(max)) {
      ret[name] = { $lte: max };
    }
    return ret;
  },
  parseWhereItem (name, arr) {
    const ret = {};
    if (!Array.isArray(arr) && arr !== null) {
      ret[name] = arr;
    // eslint-disable-next-line no-empty
    } else if (!Array.isArray(arr) && arr === null) {

    } else if (Array.isArray(arr) && arr.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      ret[name] = arr[0];
    } else if (Array.isArray(arr) && arr.length > 1) {
      ret[name] = { $in: arr };
    }
    return ret;
  },
};

</script>
