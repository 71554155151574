<template>
  <b-form-datepicker
    v-model="internalValue"
    :reset-button="resetButton"
    :disabled="disabled"
    :min="min" :max="max"
    value-as-date dropup
    :placeholder="placeholder"
    :state="state"
  />
</template>

<script>
const FORMAT = 'DD MMM YYYY';

import * as moment from 'moment';
export default {
  props: ['value', 'disabled', 'resetButton', 'allowNull', 'format', 'disabledDates', 'state', 'placeholder', 'min', 'max'],
  data () {
    return {
      internalValue: null,
    };
  },
  watch: {
    value () {
      if (this.value instanceof Error) {
        this.checkValue(null);
      } else {
        this.checkValue(this.value);
      }
    },
    internalValue () {
      this.emit();
    }
  },
  mounted () {
    if (this.allowNull === undefined) {
      this.internalValue = new Date();
    }
    if (this.value) {
      this.checkValue(this.value);
    }
  },
  methods: {
    emit () {
      if (this.internalValue === null) {
        return this.$emit('input', null);
      }
      this.$emit('input', moment(this.internalValue).utc(true).toDate());
    },
    checkValue (value) {
      if (!value) {
        return;
      }
      const date = moment(value);
      const internalValue = moment(this.internalValue).utc(true);
      if (date.isSame(internalValue)){
        return;
      }
      this.internalValue = date.toDate();
    }
  },
  format () {
    return FORMAT;
  },
};
</script>
