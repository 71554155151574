<template>
  <div>
    <b-card title="Check in-out report">
      <b-form-group label-cols-sm="4" label-cols-lg="3" label="Date">
        <Datepicker v-model="date" />
      </b-form-group>
      <b-form-group label-cols-sm="4" label-cols-lg="3" label="Properties">
        <blueprint-search-input
          v-model="propertyId"
          :multiple="true"
          placeholder="Type to search Properties"
          label="name"
          track-by="id"
          api-route-path="inventory/properties"
          @input="buildParams"
        />
      </b-form-group>
      <br>
      <b-row>
        <b-col>
          <h5>Check Outs</h5>
          <blueprint-data
            :params="params"
            :where="where"
            api-route-path="reports/bookings/checkouts"
            no-actions no-search no-new
            :fields="fields"
          >
            <template v-slot:number="{ item }">
              <router-link :to="{ name: 'bookings.booking.edit', params: { id: item.id } }">
                {{ item.number }}
              </router-link>
            </template>
            <template v-slot:range="{ item }">
              {{ item.range.start | moment('LL') }} - {{ item.range.end | moment('LL') }}
            </template>
            <template v-slot:unitId="{ item }">
              {{ item.unit.name }} / {{ item.unit.property.name }}
            </template>
          </blueprint-data>
        </b-col>
        <b-col>
          <h5>Check Ins</h5>
          <blueprint-data
            :params="params"
            :where="where"
            api-route-path="reports/bookings/checkins"
            no-actions no-search no-new
            :fields="fields"
          >
            <template v-slot:number="{ item }">
              <router-link :to="{ name: 'bookings.booking.edit', params: { id: item.id } }">
                {{ item.number }}
              </router-link>
            </template>
            <template v-slot:range="{ item }">
              {{ item.range.start | moment('LL') }} - {{ item.range.end | moment('LL') }}
            </template>
            <template v-slot:unitId="{ item }">
              {{ item.unit.name }} / {{ item.unit.property.name }}
            </template>
          </blueprint-data>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Datepicker from '../../../components/date-picker';
import utils from '../../../libs/utils.vue';

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      where: null,
      params: null,
      date: new Date(),
      propertyId: null,
      fields: [
        {
          key: 'number',
          label: 'No.',
          sortable: true,
          slot: true,
        },
        {
          key: 'unitId',
          label: 'Unit / Property',
          sortable: true,
          slot: true,
        },
        /*
        {
          key: 'range',
          label: 'Start - End',
          sortable: true,
          slot: true,
        },
        */
      ],
    };
  },
  watch: {
    propertyid () {
      this.buildParams();
    },
    date () {
      this.buildParams();
    }
  },
  methods: {
    buildParams () {
      this.where = {
        ...utils.parseWhereItem('unit.propertyId', this.propertyId),
      };
      this.params = {
        date: this.date,
      };
    }
  },
};
</script>
